import {DELETE_CIHAZ_BY_CODE, GET_CIHAZ_BY_CODE, GET_CIHAZ_LIST, GET_DEFINITION_LIST} from "../../constants";

const INITIAL_STATE = {
  cihazList: [],
  cihaz: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CIHAZ_LIST:
      return {
        ...state,
        cihazList: action.payload
      }

    case GET_CIHAZ_BY_CODE:
      return {
        ...state,
        cihaz: action.payload
      }

    case DELETE_CIHAZ_BY_CODE:
      return {
        ...state,
        //cihazList: state.cihazList.list.filter(cihaz => cihaz.code !== action.payload)
        cihazList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

