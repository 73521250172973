module.exports = global.config = {
  menu: {
    active: "",
  },
  table: {
    pageSize: 20,
    defaultColDef: {
      sortable: true
    }
  }
};
