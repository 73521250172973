import {DELETE_ADRES_BY_CODE, GET_ADRES_BY_CODE, GET_ADRES_LIST, GET_DEFINITION_LIST} from "../../constants";

const INITIAL_STATE = {
  adreslerList: [],
  adresler: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ADRES_LIST:
      return {
        ...state,
        adreslerList: action.payload
      }

    case GET_ADRES_BY_CODE:
      return {
        ...state,
        adresler: action.payload
      }

    case DELETE_ADRES_BY_CODE:
      return {
        ...state,
        //adreslerList: state.adreslerList.list.filter(adresler => adresler.code !== action.payload)
        adreslerList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

