import {DELETE_CEKMECE_BY_CODE, GET_CEKMECE_BY_CODE, GET_CEKMECE_LIST, GET_DEFINITION_LIST} from "../../constants";

const INITIAL_STATE = {
  cekmeceList: [],
  cekmece: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CEKMECE_LIST:
      return {
        ...state,
        cekmeceList: action.payload
      }

    case GET_CEKMECE_BY_CODE:
      return {
        ...state,
        cekmece: action.payload
      }

    case DELETE_CEKMECE_BY_CODE:
      return {
        ...state,
        //cekmeceList: state.cekmeceList.list.filter(cekmece => cekmece.code !== action.payload)
        cekmeceList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

