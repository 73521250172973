import {DELETE_DISTRICT_BY_CODE, GET_DEFINITION_LIST, GET_DISTRICT_BY_CODE, GET_DISTRICT_LIST} from "../../constants";

const INITIAL_STATE = {
  districtList: [],
  district: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_DISTRICT_LIST:
      return {
        ...state,
        districtList: action.payload
      }

    case GET_DISTRICT_BY_CODE:
      return {
        ...state,
        district: action.payload
      }

    case DELETE_DISTRICT_BY_CODE:
      return {
        ...state,
        //districtList: state.districtList.list.filter(district => district.code !== action.payload)
        districtList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

