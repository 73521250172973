import {
  DELETE_ACCESSORY_BY_CODE,
  GET_ACCESSORY_BY_CODE,
  GET_ACCESSORY_LIST,
  GET_DEFINITION_LIST
} from "../../constants";

const INITIAL_STATE = {
  accessoryList: [],
  accessory: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ACCESSORY_LIST:
      return {
        ...state,
        accessoryList: action.payload
      }

    case GET_ACCESSORY_BY_CODE:
      return {
        ...state,
        accessory: action.payload
      }

    case DELETE_ACCESSORY_BY_CODE:
      return {
        ...state,
        //accessoryList: state.accessoryList.list.filter(accessory => accessory.code !== action.payload)
        accessoryList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

