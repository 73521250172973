import {
  DELETE_MUSTERICIHAZ_BY_CODE,
  GET_DEFINITION_LIST,
  GET_MUSTERICIHAZ_BY_CODE,
  GET_MUSTERICIHAZ_LIST
} from "../../constants";

const INITIAL_STATE = {
  mustericihazlarList: [],
  mustericihazlar: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MUSTERICIHAZ_LIST:
      return {
        ...state,
        mustericihazlarList: action.payload
      }

    case GET_MUSTERICIHAZ_BY_CODE:
      return {
        ...state,
        mustericihazlar: action.payload
      }

    case DELETE_MUSTERICIHAZ_BY_CODE:
      return {
        ...state,
        //mustericihazlarList: state.mustericihazlarList.list.filter(mustericihazlar => mustericihazlar.code !== action.payload)
        mustericihazlarList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

