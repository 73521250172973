import React, {useEffect, useState} from "react"
import {Badge, DropdownItem, DropdownMenu, DropdownToggle, Media, UncontrolledDropdown} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import * as Icon from "react-feather"
import {history} from "../../../history"
import NotificationService from "../../../redux/actions/notification/NotificationService";
import {useHistory} from "react-router-dom";

const notificationData_ = React.createContext({
  isLoaded: false,
  firsLoaded: false,
  count: 0,
  data: []
})

const UserDropdown = props => {
  return (
    <DropdownMenu right>
      <DropdownItem tag="a" href="/#/profil-sayfasi">
        <Icon.User size={14} className="mr-50"/>
        <span className="align-middle">Profil Sayfası</span>
      </DropdownItem>
      <DropdownItem tag="a" href="/#/sifre-degistir">
        <Icon.Lock size={14} className="mr-50"/>
        <span className="align-middle">Şifre Değiştir</span>
      </DropdownItem>
      <DropdownItem divider/>
      <DropdownItem
        tag="a"
        href="/#/cikis-yap"
        onClick={e => history.push("/cikis-yap")}
      >
        <Icon.Power size={14} className="mr-50"/>
        <span className="align-middle">Çıkış Yap</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

function useBackgroundService(notificationContext) {

  useEffect(() => {
    notificationContext.isLoaded = false
    console.log("giriş")
    if (notificationContext.firsLoaded === false) {
      console.log("fl = false")
      NotificationService.loadUserNotification().then(notificationResponse => {
        notificationContext.notificationCount = notificationResponse.notificationCount;
        notificationContext.notificationData = notificationResponse.notificationData;
        notificationContext.firsLoaded = true;
        notificationContext.isLoaded = true;
        console.log("fl = true")
        return notificationContext
      });
    }

    setInterval(() => {
      NotificationService.loadUserNotification().then(notificationResponse => {
        notificationContext.notificationCount = notificationResponse.notificationCount;
        notificationContext.notificationData = notificationResponse.notificationData;
        notificationContext.firsLoaded = true;
        notificationContext.isLoaded = true;
        console.log("notificationContext", notificationContext)
        return notificationContext
      });
    }, 2500)

    function cleanup() {
      console.log("cleanup")
      clearInterval(notificationContext)
    }

    return cleanup;

  }, [notificationContext])
}

function NavbarUser(props) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [notificationData, setNotificationData] = useState()
  const history_ = useHistory()

  useEffect(() => {
    handleNotification()
  }, [])

  useEffect(() => {
    if (isLoaded) {
      let timer = setInterval(() => {
        handleNotification()
      }, 15000);
      return () => clearInterval(timer); // cleanup the timer
    }
  }, [isLoaded])

  const handleNotificationSeen = (e, nData, isGo) => {
    //e.preventDefault()
    console.log(nData)
    const postData = {
      nCode: nData.code
    }
    //history_.push(nData.url)
    //window.open("/#" + nData.url);
    history_.push({
      pathname: `${nData.url}`
    })
    /*NotificationService.setNotificationSeen(postData).then(notificationSeenResponse => {
      setNotificationResponseData(notificationSeenResponse)

    })
    if (isGo) {
      //history.push('/#' + nData.url)
      history.push({
        pathname: `/#${nData.url}`
      })
    }*/
  }

  const handleAllNotificationSeen = (e) => {
    e.preventDefault()
    const arr = []
    Object.keys(notificationData.notificationData).map(key => {
      let nData = notificationData.notificationData[key]
      arr.push(nData.code)
    })
    const postData2 = {
      codeList: arr
    }
    NotificationService.setAllNotificationSeen(postData2).then(notificationSeenResponse => {
      setNotificationResponseData(notificationSeenResponse)
    })
  }

  const setNotificationResponseData = (notificationResponse) => {
    let notificationData_ = {}

    notificationData_.notificationCount = notificationResponse.notificationCount;
    notificationData_.notificationData = notificationResponse.notificationData;
    notificationData_.firsLoaded = true;
    setIsLoaded(true)
    setNotificationData(notificationData_)
  }

  const handleNotification = async () => {
    NotificationService.loadUserNotification().then(notificationResponse => {
      setNotificationData(notificationResponse)
      setIsLoaded(true)
      //notificationContext.notificationCount = notificationResponse.notificationCount;
      //notificationContext.notificationData = notificationResponse.notificationData;
      //notificationContext.firsLoaded = true;
      //notificationContext.isLoaded = true;
      //console.log("fl = true")
      //return notificationContext
    });
  };

  useEffect(() => {
    if (isLoaded === false) {
      //alert(1)
    }
  }, [notificationData])

  return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      {process.env.NODE_ENV === "development" && isLoaded && <>
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={21}/>
            {isLoaded === true && notificationData.notificationCount > 0 &&
              <Badge pill color="primary" className="badge-up">
                {" "}
                {isLoaded === true && notificationData.notificationCount}
              </Badge>
            }
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">{isLoaded === true && notificationData.notificationCount} Yeni</h3>
                <span className="notification-title">Bildiriminiz Var</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}
            >
              {notificationData.notificationData && Object.keys(notificationData.notificationData).map(key_ => {
                let nData = notificationData.notificationData[key_]
                return (
                  <div className="d-flex justify-content-between" key={key_}>
                    <Media className="d-flex align-items-start">
                      <Media left onClick={e => handleNotificationSeen(e, nData, false)}>
                        <Icon.Check
                          className={`font-medium-5 ${nData.title}`}
                          size={21}
                        />
                      </Media>
                      <Media body onClick={e => handleNotificationSeen(e, nData, true)}>
                        <Media heading className="primary media-heading" tag="h6">
                          {nData.title}
                        </Media>
                        <p className="notification-text">
                          {nData.msg}
                        </p>
                      </Media>
                      <small>
                        <time
                          className="media-meta"
                          dateTime="2015-06-11T18:29:20+08:00"
                        >
                          9 hours ago
                        </time>
                      </small>
                    </Media>
                  </div>
                )
              })}
            </PerfectScrollbar>
            <li className="dropdown-menu-footer" key={99767}>
              <DropdownItem tag="a" className="p-1 text-center" onClick={(e) => {
                handleAllNotificationSeen(e)
              }}>
                <span className="align-middle">Tümünü Okundu İşaretle</span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>

      </>}
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {props.userName}
              </span>
            <span className="user-status">{props.instutionName}</span>
          </div>
          <span data-tour="user">
              <img
                src={props.userImg}
                className="round"
                height="40"
                width="40"
                alt="Kullanıcı Profil Resmi"
              />
            </span>
        </DropdownToggle>
        <UserDropdown {...props} />
      </UncontrolledDropdown>
    </ul>
  )

}

export default NavbarUser
