import axios from "axios";

export const setAxiosHeader = () => {
  axios.defaults.headers.common["apiVersion"] = 1;
  axios.defaults.headers.common["requestType"] = "web";
}

export const setAxiosCustomHeader = (val) => {
  //alert(val)
  //global.config.menu.active = val;
  //axios.interceptors.request.use(function (config) {
  //config.headers.moduleName = val;
  //return config;
  //});
}
