import {DELETE_MUSTERI_BY_CODE, GET_DEFINITION_LIST, GET_MUSTERI_BY_CODE, GET_MUSTERI_LIST} from "../../constants";

const INITIAL_STATE = {
  musterilerList: [],
  musteriler: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MUSTERI_LIST:
      return {
        ...state,
        musterilerList: action.payload
      }

    case GET_MUSTERI_BY_CODE:
      return {
        ...state,
        musteriler: action.payload
      }

    case DELETE_MUSTERI_BY_CODE:
      return {
        ...state,
        //musterilerList: state.musterilerList.list.filter(musteriler => musteriler.code !== action.payload)
        musterilerList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

