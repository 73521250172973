import {DELETE_CALISAN_BY_CODE, GET_CALISAN_BY_CODE, GET_CALISAN_LIST, GET_DEFINITION_LIST} from "../../constants";

const INITIAL_STATE = {
  calisanList: [],
  calisan: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CALISAN_LIST:
      return {
        ...state,
        calisanList: action.payload
      }

    case GET_CALISAN_BY_CODE:
      return {
        ...state,
        calisan: action.payload
      }

    case DELETE_CALISAN_BY_CODE:
      return {
        ...state,
        //calisanList: state.calisanList.list.filter(calisan => calisan.code !== action.payload)
        calisanList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

