import {
  DELETE_INSTITUTION_STAFF_BY_CODE,
  GET_INSTITUTION_STAFF_BY_INSTITUTION_CODE,
  GET_INSTITUTION_STAFF_DETAIL_BY_CODE
} from "../../../constants";

const INITIAL_STATE = {
  yetkililerList: [],
  yetkili: {}
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_INSTITUTION_STAFF_BY_INSTITUTION_CODE:
      return {
        ...state,
        yetkililerList: action.payload
      }

    case GET_INSTITUTION_STAFF_DETAIL_BY_CODE:
      return {
        ...state,
        yetkili: action.payload
      }

    case DELETE_INSTITUTION_STAFF_BY_CODE:
      return {
        ...state,
        yetkililerList: state
      }
    default:
      return state;
  }
}
