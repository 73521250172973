import {DELETE_DOSYA_BY_CODE, GET_DEFINITION_LIST, GET_DOSYA_LIST} from "../../constants";

const INITIAL_STATE = {
  dosyaList: [],
  //dosya: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_DOSYA_LIST:
      return {
        ...state,
        dosyaList: action.payload
      }

    case DELETE_DOSYA_BY_CODE:
      return {
        ...state,
        dosyaList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

