import axios from "axios";

/*export const setAxiosCustomResponse  = () => {
  axios.interceptors.response.use((response) => {
    //console.log("0000", response);
    return response;
  }, (error) => {
    if (error.response.status === 401) {
      //alert("401 ERROR");
      return window.location.href = '/#/error-401'
    } else if (error.response.status === 404) {
      alert("404 ERROR");
      return window.location.href = '/#/error-404'
    } else if (error.response.status === 405) {
      alert("405 ERROR");
      return window.location.href = '/#/error-405'
    } else if (error.response.status === 500) {
      alert("500 ERROR");
      return window.location.href = '/#/error-500'
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  });

}
*/
export default {
  setupInterceptors: (store, history) => {

    axios.interceptors.response.use(response => {
      return response;
    }, error => {


      if (typeof error.response !== "undefined") {


        if (error.response.status === 401) {
          //store.dispatch(logoutUser());
          history.push("/#/error-401");
          window.location.reload();
        }

        if (error.response.status === 404) {
          //history.push('/not-found');
        }

      }

      return Promise.reject(error);
    });
  },
};
