import {DELETE_MODEL_BY_CODE, GET_DEFINITION_LIST, GET_MODEL_BY_CODE, GET_MODEL_LIST} from "../../constants";

const INITIAL_STATE = {
  modelList: [],
  model: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MODEL_LIST:
      return {
        ...state,
        modelList: action.payload
      }

    case GET_MODEL_BY_CODE:
      return {
        ...state,
        model: action.payload
      }

    case DELETE_MODEL_BY_CODE:
      return {
        ...state,
        //modelList: state.modelList.list.filter(model => model.code !== action.payload)
        modelList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

