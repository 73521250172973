import {
  DELETE_FATURABILGILERI_BY_CODE,
  GET_DEFINITION_LIST,
  GET_FATURABILGILERI_BY_CODE,
  GET_FATURABILGILERI_LIST
} from "../../constants";

const INITIAL_STATE = {
  faturabilgileriList: [],
  faturabilgileri: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_FATURABILGILERI_LIST:
      return {
        ...state,
        faturabilgileriList: action.payload
      }

    case GET_FATURABILGILERI_BY_CODE:
      return {
        ...state,
        faturabilgileri: action.payload
      }

    case DELETE_FATURABILGILERI_BY_CODE:
      return {
        ...state,
        //faturabilgileriList: state.faturabilgileriList.list.filter(faturabilgileri => faturabilgileri.code !== action.payload)
        faturabilgileriList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

