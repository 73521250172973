import {LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT} from "../../actions/auth/authAction";

const initState = {
  user: '',
  isAuthenticated: false,
  error: false,
  errorMessage: '',
  menuList: []
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        outsideSalerUser: action.user.outsideSalerUser,
        isAuthenticated: true,
        error: false,
        errorMessage: '',
        menuList: action.list
      };

    case LOGIN_ERROR:
      return {
        ...state,
        user: '',
        outsideSalerUser: '',
        error: true,
        isAuthenticated: false,
        errorMessage: action.error,
        menuList: []
      };

    case LOGOUT:
      return {
        user: ''
      };

    default:
      return state;
  }
}

export default authReducer;
