import {DELETE_KURUM_BY_CODE, GET_KURUM_BY_CODE, GET_KURUMLAR_LIST, UPDATE_KURUM} from "../../constants";

const INITIAL_STATE = {
  kurumlarList: [],
  kurum: {},
  updateResponse: {}
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_KURUMLAR_LIST:
      return {
        ...state,
        kurumlarList: action.payload
      }

    case GET_KURUM_BY_CODE:
      return {
        ...state,
        kurum: action.payload
      }

    case UPDATE_KURUM:
      return {
        ...state,
        updateResponse: action.payload
      }

    case DELETE_KURUM_BY_CODE:
      return {
        ...state,
        kurumlarList: state
      }

    default:
      return state;
  }
}
