import {combineReducers} from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import selectBoxReducers from "./commons/selectBoxReducers";
import menuReducers from "./menu/menuReducers";
import authReducer from "./auth/authReducer";
import errorReducer from "./error/errorReducer";
import kurumlarReducers from "./kurumlar/kurumlarReducers";
import yetkililerReducers from "./kurumlar/yetkililer/yetkililerReducers";
import turlerReducers from "./tanimlar/turTanimReducers";
import markalarReducers from "./markalar/markalarReducers";
import citiesReducers from "./cities/citiesReducers";
import countiesReducers from "./counties/countiesReducers";
import districtsReducers from "./districts/districtsReducers";
import accessoriesReducers from "./accessories/accessoriesReducers";
import modellerReducers from "./modeller/modellerReducers";
import kullaniciizinReducers from "./kullaniciizin/kullaniciizinReducers";
import outsideserviceReducers from "./outsideservice/outsideserviceReducers";
import replacementdeviceReducers from "./replacementdevice/replacementdeviceReducers";
import calisanReducers from "./calisan/calisanReducers";
import cihazReducers from "./cihaz/cihazReducers";
import rolReducers from "./rol/rolReducers";
import depoReducers from "./depo/depoReducers";
import dolapReducers from "./dolap/dolapReducers";
import cekmeceReducers from "./cekmece/cekmeceReducers";
import notReducers from "./not/notReducers";
import telefongorusmesiReducers from "./telefongorusmesi/telefongorusmesiReducers";
import calisanyakinlarReducers from "./calisanyakinlar/calisanyakinlarReducers";
import dosyaReducers from "./dosya/dosyaReducers";
import kullanicilarReducers from "./kullanicilar/kullanicilarReducers";
import tedarikcilerReducers from "./tedarikciler/tedarikcilerReducers";
import parcalarReducers from "./parcalar/parcalarReducers";
import gorevetiketlerReducers from "./gorevetiketler/gorevetiketlerReducers";
import anlasmalarReducers from "./anlasmalar/anlasmalarReducers";
import subelerReducers from "./subeler/subelerReducers";
import musterilerReducers from "./musteriler/musterilerReducers";
import faturabilgileriReducers from "./faturabilgileri/faturabilgileriReducers";
import adreslerReducers from "./adresler/adreslerReducers";
import musterifaturalarReducers from "./musterifaturalar/musterifaturalarReducers";
import mustericihazlarReducers from "./mustericihazlar/mustericihazlarReducers";
import musteriiletisimbilgileriReducers from "./musteriiletisimbilgileri/musteriiletisimbilgileriReducers";
import hizmetlerReducers from "./hizmetler/hizmetlerReducers";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  selectBoxInstitutionList: selectBoxReducers,
  selectBoxEmployeeList: selectBoxReducers,
  selectBoxBrandList: selectBoxReducers,
  selectBoxModelList: selectBoxReducers,
  selectBoxWareHouseList: selectBoxReducers,
  selectBoxCabinetList: selectBoxReducers,
  selectBoxCityList: selectBoxReducers,
  selectBoxCountyList: selectBoxReducers,
  selectBoxDeviceList: selectBoxReducers,
  menuList: menuReducers,
  authentication: authReducer,
  errors: errorReducer,
  kurumlarList: kurumlarReducers,
  yetkililerList: yetkililerReducers,
  turlerList: turlerReducers,
  tanimlarList: turlerReducers,
  markaList: markalarReducers,
  cityList: citiesReducers,
  countyList: countiesReducers,
  districtList: districtsReducers,
  accessoryList: accessoriesReducers,
  modelList: modellerReducers,
  kullaniciizinList: kullaniciizinReducers,
  outsideserviceList: outsideserviceReducers,
  replacementdeviceList: replacementdeviceReducers,
  cihazList: cihazReducers,
  rolList: rolReducers,
  depoList: depoReducers,
  dolapList: dolapReducers,
  cekmeceList: cekmeceReducers,
  notList: notReducers,
  telefongorusmesiList: telefongorusmesiReducers,
  calisanList: calisanReducers,
  calisanyakinlarList: calisanyakinlarReducers,
  dosyaList: dosyaReducers,
  kullanicilarList: kullanicilarReducers,
  tedarikcilerList: tedarikcilerReducers,
  parcalarList: parcalarReducers,
  gorevetiketlerList: gorevetiketlerReducers,
  anlasmalarList: anlasmalarReducers,
  subelerList: subelerReducers,
  musterilerList: musterilerReducers,
  faturabilgileriList: faturabilgileriReducers,
  adreslerList: adreslerReducers,
  musterifaturalarList: musterifaturalarReducers,
  mustericihazlarList: mustericihazlarReducers,
  musteriiletisimbilgileriList: musteriiletisimbilgileriReducers,
  hizmetlerList: hizmetlerReducers
});
export default rootReducer
