import {DELETE_NOT_BY_CODE, GET_DEFINITION_LIST, GET_NOT_BY_CODE, GET_NOT_LIST} from "../../constants";

const INITIAL_STATE = {
  notList: [],
  not: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_NOT_LIST:
      return {
        ...state,
        notList: action.payload
      }

    case GET_NOT_BY_CODE:
      return {
        ...state,
        not: action.payload
      }

    case DELETE_NOT_BY_CODE:
      return {
        ...state,
        //notList: state.notList.list.filter(not => not.code !== action.payload)
        notList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

