import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import classnames from "classnames"
import logo from "../../../../assets/img/logo/logo.png"

class SidebarHeader extends Component {
  render() {
    let {
      menuShadow
    } = this.props
    return (
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <NavLink to="/ozet-ekrani" className="navbar-brand p-1">
              <div className="brand-logo ">
                <img
                  src={logo}
                  alt="Fixplan App"
                  className="p-0-2"
                  style={{width: '90%'}}
                />
              </div>
            </NavLink>
          </li>
        </ul>
        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false
          })}
        />
      </div>
    )
  }
}

export default SidebarHeader



