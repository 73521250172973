import axios from 'axios';
import {setAuthorizationToken} from '../../helpers/setAuthorizationToken';
import {toastMessageBuilder} from "../../../views/pages/ortak/Toaster";

const login = (username, password) => {
  return axios.post("/auth/login", {username, password})
    .then(user => {
      if (user.data) {
        const {token} = user.data;
        localStorage.setItem("jwtToken", token);
        const serializedState = JSON.stringify(user.data.menuList);
        localStorage.setItem("menuList", serializedState);
        if (user.data.user) {
          localStorage.setItem("fullName", user.data.user.fullName);
          localStorage.setItem("userCode", user.data.user.code);
          localStorage.setItem("userProfile", user.data.user.profil_picture);
          localStorage.setItem("instutionName", user.data.user.institutionId_name);
          localStorage.setItem("instutionNameFull", user.data.user.institutionId_namefull);
          localStorage.setItem("centerUser", user.data.user.centerUser);
          localStorage.setItem("outsideSalerUser", user.data.user.outsideSalerUser);
          localStorage.setItem("centerRole", user.data.user.centerRole);
        }
        toastMessageBuilder(user);
        setAuthorizationToken(token);
      }

      return user.data;
    })
    .catch(err => {
      console.log('ERROR: ', err.response)
      toastMessageBuilder(err.response)
    });
}

export const getUserData = (code) => {
  return axios.get(`/auth/user/${code}`)
    .then(user => {
      return user.data.users;
    })
    .catch(err => {
      console.log('ERROR: ', err.response)
      toastMessageBuilder(err.response)
    });
}

const logout = () => {
  axios.post('/auth/logout');
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("menuList");
  localStorage.removeItem("fullName");
  localStorage.removeItem("instutionName");
  localStorage.removeItem("centerUser");
  localStorage.removeItem("outsideUser");
  localStorage.removeItem("centerRole");
  setAuthorizationToken(false);
}

export default {login, logout};
