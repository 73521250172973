import {
  DELETE_MUSTERIFATURA_BY_CODE,
  GET_DEFINITION_LIST,
  GET_MUSTERIFATURA_BY_CODE,
  GET_MUSTERIFATURA_LIST
} from "../../constants";

const INITIAL_STATE = {
  musterifaturalarList: [],
  musterifaturalar: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MUSTERIFATURA_LIST:
      return {
        ...state,
        musterifaturalarList: action.payload
      }

    case GET_MUSTERIFATURA_BY_CODE:
      return {
        ...state,
        musterifaturalar: action.payload
      }

    case DELETE_MUSTERIFATURA_BY_CODE:
      return {
        ...state,
        //musterifaturalarList: state.musterifaturalarList.list.filter(musterifaturalar => musterifaturalar.code !== action.payload)
        musterifaturalarList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

