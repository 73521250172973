import {
  DELETE_CALISANYAKIN_BY_CODE,
  GET_CALISANYAKIN_BY_CODE,
  GET_CALISANYAKIN_LIST,
  GET_DEFINITION_LIST
} from "../../constants";

const INITIAL_STATE = {
  calisanyakinlarList: [],
  calisanyakinlar: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CALISANYAKIN_LIST:
      return {
        ...state,
        calisanyakinlarList: action.payload
      }

    case GET_CALISANYAKIN_BY_CODE:
      return {
        ...state,
        calisanyakinlar: action.payload
      }

    case DELETE_CALISANYAKIN_BY_CODE:
      return {
        ...state,
        //calisanyakinlarList: state.calisanyakinlarList.list.filter(calisanyakinlar => calisanyakinlar.code !== action.payload)
        calisanyakinlarList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

