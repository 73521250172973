import {DELETE_CITY_BY_CODE, GET_CITY_BY_CODE, GET_CITY_LIST, GET_DEFINITION_LIST} from "../../constants";

const INITIAL_STATE = {
  cityList: [],
  city: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CITY_LIST:
      return {
        ...state,
        cityList: action.payload
      }

    case GET_CITY_BY_CODE:
      return {
        ...state,
        city: action.payload
      }

    case DELETE_CITY_BY_CODE:
      return {
        ...state,
        //cityList: state.cityList.list.filter(city => city.code !== action.payload)
        cityList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

