import {
  DELETE_MUSTERIILETISIMBILGI_BY_CODE,
  GET_DEFINITION_LIST,
  GET_MUSTERIILETISIMBILGI_BY_CODE,
  GET_MUSTERIILETISIMBILGI_LIST
} from "../../constants";

const INITIAL_STATE = {
  musteriiletisimbilgileriList: [],
  musteriiletisimbilgileri: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MUSTERIILETISIMBILGI_LIST:
      return {
        ...state,
        musteriiletisimbilgileriList: action.payload
      }

    case GET_MUSTERIILETISIMBILGI_BY_CODE:
      return {
        ...state,
        musteriiletisimbilgileri: action.payload
      }

    case DELETE_MUSTERIILETISIMBILGI_BY_CODE:
      return {
        ...state,
        //musteriiletisimbilgileriList: state.musteriiletisimbilgileriList.list.filter(musteriiletisimbilgileri => musteriiletisimbilgileri.code !== action.payload)
        musteriiletisimbilgileriList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

