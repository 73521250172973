import {
  DELETE_ROL_BY_CODE,
  GET_DEFINITION_LIST,
  GET_ROL_AUTH_LIST_BY_CODE,
  GET_ROL_BY_CODE,
  GET_ROL_LIST,
  GET_ROL_METHOD_LIST_BY_CODE
} from "../../constants";

const INITIAL_STATE = {
  rolList: [],
  rol_row: {},
  definitionList: [],
  roleAuthList: [],
  roleMethodList: [],
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ROL_LIST:
      return {
        ...state,
        rolList: action.payload
      }

    case GET_ROL_BY_CODE:
      return {
        ...state,
        rol_row: action.payload
      }

    case DELETE_ROL_BY_CODE:
      return {
        ...state,
        //rolList: state.rolList.list.filter(rol => rol.code !== action.payload)
        rolList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }
    case GET_ROL_AUTH_LIST_BY_CODE:
      return {
        ...state,
        roleAuthList: action.payload
      }
    case GET_ROL_METHOD_LIST_BY_CODE:
      return {
        ...state,
        roleMethodList: action.payload
      }

    default:
      return state;
  }
}

