import axios from "axios";
import {toastMessageBuilder2} from "../../../views/pages/ortak/Toaster";

class NotificationService {

  async loadUserNotification() {
    let returnData = []
    try {
      await axios.get(`/api/notification/user-notifications`).then((result) => {
        returnData = result
      });
      return returnData.data;
    } catch (error) {
      toastMessageBuilder2(error.response);
    }
  }

  async setNotificationSeen(data) {
    let returnData = []
    try {
      await axios.post(`/api/notification/set-notification-seen`, data).then((result) => {
        returnData = result
      });
      return returnData.data;
    } catch (error) {
      toastMessageBuilder2(error.response);
    }
  }

  async setAllNotificationSeen(data) {
    let returnData = []
    try {
      await axios.post(`/api/notification/set-all-notification-seen`, data).then((result) => {
        returnData = result
      });
      return returnData.data;
    } catch (error) {
      toastMessageBuilder2(error.response);
    }
  }

}

export default new NotificationService();
