import {
  DELETE_REPLACEMENTDEVICE_BY_CODE,
  GET_DEFINITION_LIST,
  GET_REPLACEMENTDEVICE_BY_CODE,
  GET_REPLACEMENTDEVICE_LIST
} from "../../constants";

const INITIAL_STATE = {
  replacementdeviceList: [],
  replacementdevice: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_REPLACEMENTDEVICE_LIST:
      return {
        ...state,
        replacementdeviceList: action.payload
      }

    case GET_REPLACEMENTDEVICE_BY_CODE:
      return {
        ...state,
        replacementdevice: action.payload
      }

    case DELETE_REPLACEMENTDEVICE_BY_CODE:
      return {
        ...state,
        //replacementdeviceList: state.replacementdeviceList.list.filter(replacementdevice => replacementdevice.code !== action.payload)
        replacementdeviceList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

