import {DELETE_COUNTY_BY_CODE, GET_COUNTY_BY_CODE, GET_COUNTY_LIST, GET_DEFINITION_LIST} from "../../constants";

const INITIAL_STATE = {
  countyList: [],
  county: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COUNTY_LIST:
      return {
        ...state,
        countyList: action.payload
      }

    case GET_COUNTY_BY_CODE:
      return {
        ...state,
        county: action.payload
      }

    case DELETE_COUNTY_BY_CODE:
      return {
        ...state,
        //countyList: state.countyList.list.filter(county => county.code !== action.payload)
        countyList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

