import {DELETE_ANLASMA_BY_CODE, GET_ANLASMA_BY_CODE, GET_ANLASMA_LIST, GET_DEFINITION_LIST} from "../../constants";

const INITIAL_STATE = {
  anlasmalarList: [],
  anlasmalar: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ANLASMA_LIST:
      return {
        ...state,
        anlasmalarList: action.payload
      }

    case GET_ANLASMA_BY_CODE:
      return {
        ...state,
        anlasmalar: action.payload
      }

    case DELETE_ANLASMA_BY_CODE:
      return {
        ...state,
        //anlasmalarList: state.anlasmalarList.list.filter(anlasmalar => anlasmalar.code !== action.payload)
        anlasmalarList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

