import {GET_ALL_DEFINITIONS, GET_DEFINITION_DETAIL_BY_CODE, GET_SUB_DEFINITIONS_BY_CODE} from "../../constants";

const INITIAL_STATE = {
  turlerList: [],
  tanim: [],
  altTanimlarList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_DEFINITIONS:
      return {
        ...state,
        turlerList: action.payload
      }

    case GET_DEFINITION_DETAIL_BY_CODE:
      return {
        ...state,
        tanim: action.payload
      }

    case GET_SUB_DEFINITIONS_BY_CODE:
      return {
        ...state,
        altTanimlarList: action.payload
      }

    default:
      return state;
  }
}
