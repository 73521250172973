import React, {lazy, Suspense} from "react"
import ReactDOM from "react-dom"
import {Provider} from "react-redux"
import {Layout} from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import {store} from "./redux/storeConfig/store"
import Spinner from "./components/@vuexy/spinner/Fallback-spinner"
import "./index.scss"
import {setAuthorizationToken} from "./redux/helpers/setAuthorizationToken";
import {setAxiosHeader} from "./redux/helpers/setAxiosHeader";
import "./assets/css/style.css"
import './config';
import httpService from "./redux/interceptors/interceptors";
import {history} from "./history"

const LazyApp = lazy(() => import("./App"))

const jwtToken = localStorage.getItem("jwtToken");
if (jwtToken) {
  setAuthorizationToken(jwtToken);
}
setAxiosHeader();


httpService.setupInterceptors(store, history)
// configureDatabase()
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner/>}>
      <Layout>
        <LazyApp/>
      </Layout>
    </Suspense>
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
