import {DELETE_MENU_BY_CODE, GET_DEFINITION_LIST, GET_MENU_BY_CODE, GET_MENU_LIST} from "../../constants";

const INITIAL_STATE = {
  menuList: [],
  menu: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MENU_LIST:
      return {
        ...state,
        menuList: action.payload
      }

    case GET_MENU_BY_CODE:
      return {
        ...state,
        menu: action.payload
      }

    case DELETE_MENU_BY_CODE:
      return {
        ...state,
        //menuList: state.menuList.list.filter(menu => menu.code !== action.payload)
        menuList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}
