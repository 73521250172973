import {applyMiddleware, compose, createStore} from "redux"
import createDebounce from "redux-debounced"
import thunk from "redux-thunk"
import rootReducer from "../reducers/rootReducer"
import {createLogger} from "redux-logger";

const loggerMiddleware = createLogger();
const middlewares = [thunk, createDebounce(), loggerMiddleware]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
)

export {store}
