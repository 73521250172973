import {DELETE_MARKA_BY_CODE, GET_DEFINITION_LIST, GET_MARKA_BY_CODE, GET_MARKA_LIST} from "../../constants";

const INITIAL_STATE = {
  markaList: [],
  marka: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MARKA_LIST:
      return {
        ...state,
        markaList: action.payload
      }

    case GET_MARKA_BY_CODE:
      return {
        ...state,
        marka: action.payload
      }

    case DELETE_MARKA_BY_CODE:
      return {
        ...state,
        //markaList: state.markaList.list.filter(marka => marka.code !== action.payload)
        markaList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

