import React from "react"
import {Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink} from "reactstrap"
import * as Icon from "react-feather"
import {ChevronDown} from "react-feather"

// a little function to help us with reordering the bookmarks
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

class NavbarBookmarks extends React.PureComponent {
  state = {
    showBookmarks: false,
    value: "",
    noSuggestions: false,
    isStarred: false,
    suggestions: [],
    navbarSearch: true,  // default true idi sonra araştırılacak
    aramayap: [],
    starredItems: [],
    dropdownOpen: false
  }

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  handleBookmarksVisibility = () => {
    this.setState({
      showBookmarks: !this.state.showBookmarks,
      value: "",
      suggestions: [],
      aramayap: [],
      noSuggestions: false,
      starred: null
    })
  }

  componentDidMount() {
    /*axios.get("/api/main-search/data").then(({data}) => {
      this.setState({aramayap: data.searchResult})
    })*/
  }

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const starredItems = reorder(
      this.state.starredItems,
      result.source.index,
      result.destination.index
    )

    this.setState({
      starredItems
    })
  }

  render() {
    let {
      sidebarVisibility,
      updateStarred,
      handleAppOverlay
    } = this.props

    return (
      <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu mr-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={sidebarVisibility}
            >
              <Icon.Menu className="ficon"/>
            </NavLink>
          </NavItem>
          <NavItem className="nav-item mt-1 dropdown">
            <ButtonDropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.toggleDropdown}
              className="full-width"
            >
              <DropdownToggle color="primary" caret style={{textAlign:"center"}}>
                <ChevronDown size={15}/>
              </DropdownToggle>
              <DropdownMenu style={{minWidth:200}}>
                <DropdownItem tag="a" href="/#/randevu-olustur">Randevu Oluştur</DropdownItem>
                <DropdownItem tag="a" href="/#/servis-kaydi-olustur">Servis Kaydı Oluştur</DropdownItem>
                <DropdownItem tag="a" href="/#/aksesuar-satis-yap">Aksesuar Satış</DropdownItem>
                <DropdownItem tag="a" href="/#/hizli-satis">Hızlı Satış</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </NavItem>
        </ul>
        {
          localStorage.getItem('outsideSalerUser') !== "E" && <>
            <ul className="nav navbar-nav bookmark-icons d-none d-xl-flex d-lg-none">
              <NavItem>
                <Button.Ripple
                  tag="a"
                  href="/#/randevu-olustur"
                  className="mr-1" color="primary">
                  Randevu Oluştur
                </Button.Ripple>
              </NavItem>
              <NavItem>
                <Button.Ripple
                  tag="a"
                  href="/#/servis-kaydi-olustur"
                  className="mr-1" color="primary">
                  Servis Kaydı Oluştur
                </Button.Ripple>
              </NavItem>
              <NavItem>
                <Button.Ripple
                  tag="a"
                  href="/#/aksesuar-satis-yap"
                  className="mr-1" color="primary">
                  Aksesuar Satışı Yap
                </Button.Ripple>
              </NavItem>
              <NavItem>
                <Button.Ripple
                  tag="a"
                  href="/#/hizli-satis"
                  className="mr-1" color="primary">
                  Hızlı Satış
                </Button.Ripple>
              </NavItem>
            </ul>
          </>
        }
      </div>
    )
  }
}

export default NavbarBookmarks
