import {
  DELETE_HIZMETLER_BY_CODE,
  GET_DEFINITION_LIST,
  GET_HIZMETLER_BY_CODE,
  GET_HIZMETLER_LIST
} from "../../constants";

const INITIAL_STATE = {
  hizmetlerList: [],
  hizmetler: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_HIZMETLER_LIST:
      return {
        ...state,
        hizmetlerList: action.payload
      }

    case GET_HIZMETLER_BY_CODE:
      return {
        ...state,
        hizmetler: action.payload
      }

    case DELETE_HIZMETLER_BY_CODE:
      return {
        ...state,
        //hizmetlerList: state.hizmetlerList.list.filter(hizmetler => hizmetler.code !== action.payload)
        hizmetlerList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

