import {DELETE_SUBE_BY_CODE, GET_DEFINITION_LIST, GET_SUBE_BY_CODE, GET_SUBE_LIST} from "../../constants";

const INITIAL_STATE = {
  subelerList: [],
  subeler: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SUBE_LIST:
      return {
        ...state,
        subelerList: action.payload
      }

    case GET_SUBE_BY_CODE:
      return {
        ...state,
        subeler: action.payload
      }

    case DELETE_SUBE_BY_CODE:
      return {
        ...state,
        //subelerList: state.subelerList.list.filter(subeler => subeler.code !== action.payload)
        subelerList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

