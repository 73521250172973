import {DELETE_PARCA_BY_CODE, GET_DEFINITION_LIST, GET_PARCA_BY_CODE, GET_PARCA_LIST} from "../../constants";

const INITIAL_STATE = {
  parcalarList: [],
  parcalar: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PARCA_LIST:
      return {
        ...state,
        parcalarList: action.payload
      }

    case GET_PARCA_BY_CODE:
      return {
        ...state,
        parcalar: action.payload
      }

    case DELETE_PARCA_BY_CODE:
      return {
        ...state,
        //parcalarList: state.parcalarList.list.filter(parcalar => parcalar.code !== action.payload)
        parcalarList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

