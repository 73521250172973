import {DELETE_DEPO_BY_CODE, GET_DEFINITION_LIST, GET_DEPO_BY_CODE, GET_DEPO_LIST} from "../../constants";

const INITIAL_STATE = {
  depoList: [],
  depo: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_DEPO_LIST:
      return {
        ...state,
        depoList: action.payload
      }

    case GET_DEPO_BY_CODE:
      return {
        ...state,
        depo: action.payload
      }

    case DELETE_DEPO_BY_CODE:
      return {
        ...state,
        //depoList: state.depoList.list.filter(depo => depo.code !== action.payload)
        depoList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

