import {
  DELETE_KULLANICI_BY_CODE,
  GET_DEFINITION_LIST,
  GET_KULLANICI_BY_CODE,
  GET_KULLANICI_LIST
} from "../../constants";

const INITIAL_STATE = {
  kullanicilarList: [],
  kullanicilar: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_KULLANICI_LIST:
      return {
        ...state,
        kullanicilarList: action.payload
      }

    case GET_KULLANICI_BY_CODE:
      return {
        ...state,
        kullanicilar: action.payload
      }

    case DELETE_KULLANICI_BY_CODE:
      return {
        ...state,
        //kullanicilarList: state.kullanicilarList.list.filter(kullanicilar => kullanicilar.code !== action.payload)
        kullanicilarList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

