import {
  DELETE_OUTSIDESERVICE_BY_CODE,
  GET_DEFINITION_LIST,
  GET_OUTSIDESERVICE_BY_CODE,
  GET_OUTSIDESERVICE_LIST
} from "../../constants";

const INITIAL_STATE = {
  outsideserviceList: [],
  outsideservice: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_OUTSIDESERVICE_LIST:
      return {
        ...state,
        outsideserviceList: action.payload
      }

    case GET_OUTSIDESERVICE_BY_CODE:
      return {
        ...state,
        outsideservice: action.payload
      }

    case DELETE_OUTSIDESERVICE_BY_CODE:
      return {
        ...state,
        //outsideserviceList: state.outsideserviceList.list.filter(outsideservice => outsideservice.code !== action.payload)
        outsideserviceList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

