import {
  DELETE_GOREVETIKET_BY_CODE,
  GET_DEFINITION_LIST,
  GET_GOREVETIKET_BY_CODE,
  GET_GOREVETIKET_LIST
} from "../../constants";

const INITIAL_STATE = {
  gorevetiketlerList: [],
  gorevetiketler: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_GOREVETIKET_LIST:
      return {
        ...state,
        gorevetiketlerList: action.payload
      }

    case GET_GOREVETIKET_BY_CODE:
      return {
        ...state,
        gorevetiketler: action.payload
      }

    case DELETE_GOREVETIKET_BY_CODE:
      return {
        ...state,
        //gorevetiketlerList: state.gorevetiketlerList.list.filter(gorevetiketler => gorevetiketler.code !== action.payload)
        gorevetiketlerList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

