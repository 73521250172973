//SELECT_BOX
export const GET_SELECT_BOX_MARKA_LIST = "GET_SELECT_BOX_MARKA_LIST";
export const GET_SELECT_BOX_MODEL_LIST = "GET_MARKA_MODEL_LIST";
export const GET_SELECT_BOX_MODEL_LIST_BY_BRAND_ID = "GET_SELECT_BOX_MODEL_LIST_BY_BRAND_ID";
export const GET_SELECT_BOX_DEPO_LIST = "GET_SELECT_BOX_DEPO_LIST";
export const GET_SELECT_BOX_DOLAP_LIST = "GET_SELECT_BOX_DOLAP_LIST";
export const GET_SELECT_BOX_WAREHOUSE_LIST = "GET_SELECT_BOX_WAREHOUSE_LIST";
export const GET_SELECT_BOX_CABINET_LIST_BY_WHID = "GET_SELECT_BOX_CABINET_LIST_BY_WHID";
export const GET_SELECT_BOX_INSTITUTION_LIST = "GET_SELECT_BOX_INSTITUTION_LIST";
export const GET_SELECT_BOX_EMPLOYEE = "GET_SELECT_BOX_EMPLOYEE";
export const GET_SELECT_BOX_ROLE_LIST = "GET_SELECT_BOX_ROLE_LIST";
export const GET_SELECT_BOX_CITY = "GET_SELECT_BOX_CITY";
export const GET_SELECT_BOX_COUNTY = "GET_SELECT_BOX_COUNTY";
export const GET_SELECT_BOX_DEVICE = "GET_SELECT_BOX_DEVICE";
export const GET_GENERATE_RANDOM_CODE = "GET_GENERATE_RANDOM_CODE";
export const GET_SELECT_BOX_AGREMENT_LIST = "GET_SELECT_BOX_AGREMENT_LIST";


// DOSYALAR
export const GET_DOSYA_LIST = "GET_DOSYA_LIST";
export const DELETE_DOSYA_BY_CODE = "DELETE_DOSYA_BY_CODE";


// MENULER
export const GET_MENU_LIST = "GET_MENU_LIST";
export const DELETE_MENU_BY_CODE = "DELETE_MENU_BY_CODE";
export const GET_MENU_BY_CODE = "GET_MENU_BY_CODE";
export const GET_DEFINITION_LIST = "GET_DEFINITION_LIST";


// KURUMLAR
export const GET_KURUMLAR_LIST = "GET_KURUMLAR_LIST";
export const GET_KURUM_BY_CODE = "GET_KURUM_BY_CODE";
export const DELETE_KURUM_BY_CODE = "DELETE_KURUM_BY_CODE";
export const UPDATE_KURUM = "UPDATE_KURUM";


// ORTAK
export const GET_ERRORS = "GET_ERRORS";


// NOTLAR
export const GET_NOTES_BY_INSTITUTION_CODE = "GET_NOTES_BY_INSTITUTION_CODE";
export const GET_INSTITUTION_NOTE_DETAIL_BY_CODE = "GET_INSTITUTION_NOTE_DETAIL_BY_CODE";


// YETKILILER
export const GET_INSTITUTION_STAFF_BY_INSTITUTION_CODE = "GET_INSTITUTION_STAFF_BY_INSTITUTION_CODE";
export const GET_INSTITUTION_STAFF_DETAIL_BY_CODE = "GET_INSTITUTION_STAFF_DETAIL_BY_CODE";
export const DELETE_INSTITUTION_STAFF_BY_CODE = "DELETE_INSTITUTION_STAFF_BY_CODE";


// TANIMLAR
export const GET_ALL_DEFINITIONS = "GET_ALL_DEFINITIONS";
export const GET_DEFINITION_DETAIL_BY_CODE = "GET_DEFINITION_DETAIL_BY_CODE";
export const GET_SUB_DEFINITIONS_BY_CODE = "GET_SUB_DEFINITIONS_BY_CODE";


// MARKALAR
export const GET_MARKA_LIST = "GET_MARKA_LIST";
export const DELETE_MARKA_BY_CODE = "DELETE_MARKA_BY_CODE";
export const GET_MARKA_BY_CODE = "GET_MARKA_BY_CODE";


// CITIES
export const GET_CITY_LIST = "GET_CITY_LIST";
export const DELETE_CITY_BY_CODE = "DELETE_CITY_BY_CODE";
export const GET_CITY_BY_CODE = "GET_CITY_BY_CODE";


// COUNTIES
export const GET_COUNTY_LIST = "GET_COUNTY_LIST";
export const DELETE_COUNTY_BY_CODE = "DELETE_COUNTY_BY_CODE";
export const GET_COUNTY_BY_CODE = "GET_COUNTY_BY_CODE";


// DISTRICTS
export const GET_DISTRICT_LIST = "GET_DISTRICT_LIST";
export const DELETE_DISTRICT_BY_CODE = "DELETE_DISTRICT_BY_CODE";
export const GET_DISTRICT_BY_CODE = "GET_DISTRICT_BY_CODE";


// ACCESSORIES
export const GET_ACCESSORY_LIST = "GET_ACCESSORY_LIST";
export const DELETE_ACCESSORY_BY_CODE = "DELETE_ACCESSORY_BY_CODE";
export const GET_ACCESSORY_BY_CODE = "GET_ACCESSORY_BY_CODE";


// MODELLER
export const GET_MODEL_LIST = "GET_MODEL_LIST";
export const DELETE_MODEL_BY_CODE = "DELETE_MODEL_BY_CODE";
export const GET_MODEL_BY_CODE = "GET_MODEL_BY_CODE";


// KULLANICIIZINLER
export const GET_KULLANICIIZIN_LIST = "GET_KULLANICIIZIN_LIST";
export const DELETE_KULLANICIIZIN_BY_CODE = "DELETE_KULLANICIIZIN_BY_CODE";
export const GET_KULLANICIIZIN_BY_CODE = "GET_KULLANICIIZIN_BY_CODE";


// OUTSIDESERVICES
export const GET_OUTSIDESERVICE_LIST = "GET_OUTSIDESERVICE_LIST";
export const DELETE_OUTSIDESERVICE_BY_CODE = "DELETE_OUTSIDESERVICE_BY_CODE";
export const GET_OUTSIDESERVICE_BY_CODE = "GET_OUTSIDESERVICE_BY_CODE";


// REPLACEMENTDEVICES
export const GET_REPLACEMENTDEVICE_LIST = "GET_REPLACEMENTDEVICE_LIST";
export const DELETE_REPLACEMENTDEVICE_BY_CODE = "DELETE_REPLACEMENTDEVICE_BY_CODE";
export const GET_REPLACEMENTDEVICE_BY_CODE = "GET_REPLACEMENTDEVICE_BY_CODE";


// CALISANLAR
export const GET_CALISAN_LIST = "GET_CALISAN_LIST";
export const DELETE_CALISAN_BY_CODE = "DELETE_CALISAN_BY_CODE";
export const GET_CALISAN_BY_CODE = "GET_CALISAN_BY_CODE";


// CIHAZLAR
export const GET_CIHAZ_LIST = "GET_CIHAZ_LIST";
export const DELETE_CIHAZ_BY_CODE = "DELETE_CIHAZ_BY_CODE";
export const GET_CIHAZ_BY_CODE = "GET_CIHAZ_BY_CODE";


// ROLLER
export const GET_ROL_LIST = "GET_ROL_LIST";
export const DELETE_ROL_BY_CODE = "DELETE_ROL_BY_CODE";
export const GET_ROL_BY_CODE = "GET_ROL_BY_CODE";
export const GET_ROL_AUTH_LIST_BY_CODE = "GET_ROL_AUTH_LIST_BY_CODE";
export const GET_ROL_METHOD_LIST_BY_CODE = "GET_ROL_METHOD_LIST_BY_CODE";


// DEPOLAR
export const GET_DEPO_LIST = "GET_DEPO_LIST";
export const DELETE_DEPO_BY_CODE = "DELETE_DEPO_BY_CODE";
export const GET_DEPO_BY_CODE = "GET_DEPO_BY_CODE";


// DOLAPLAR
export const GET_DOLAP_LIST = "GET_DOLAP_LIST";
export const DELETE_DOLAP_BY_CODE = "DELETE_DOLAP_BY_CODE";
export const GET_DOLAP_BY_CODE = "GET_DOLAP_BY_CODE";


// CEKMECELER
export const GET_CEKMECE_LIST = "GET_CEKMECE_LIST";
export const DELETE_CEKMECE_BY_CODE = "DELETE_CEKMECE_BY_CODE";
export const GET_CEKMECE_BY_CODE = "GET_CEKMECE_BY_CODE";


// NOTLAR
export const GET_NOT_LIST = "GET_NOT_LIST";
export const DELETE_NOT_BY_CODE = "DELETE_NOT_BY_CODE";
export const GET_NOT_BY_CODE = "GET_NOT_BY_CODE";


// TELEFONGORUSMELERI
export const GET_TELEFONGORUSMESI_LIST = "GET_TELEFONGORUSMESI_LIST";
export const DELETE_TELEFONGORUSMESI_BY_CODE = "DELETE_TELEFONGORUSMESI_BY_CODE";
export const GET_TELEFONGORUSMESI_BY_CODE = "GET_TELEFONGORUSMESI_BY_CODE";


// CALISANYAKINLAR
export const GET_CALISANYAKIN_LIST = "GET_CALISANYAKIN_LIST";
export const DELETE_CALISANYAKIN_BY_CODE = "DELETE_CALISANYAKIN_BY_CODE";
export const GET_CALISANYAKIN_BY_CODE = "GET_CALISANYAKIN_BY_CODE";


// KULLANICILAR
export const GET_KULLANICI_LIST = "GET_KULLANICI_LIST";
export const DELETE_KULLANICI_BY_CODE = "DELETE_KULLANICI_BY_CODE";
export const GET_KULLANICI_BY_CODE = "GET_KULLANICI_BY_CODE";


// TEDARIKCILER
export const GET_TEDARIKCI_LIST = "GET_TEDARIKCI_LIST";
export const DELETE_TEDARIKCI_BY_CODE = "DELETE_TEDARIKCI_BY_CODE";
export const GET_TEDARIKCI_BY_CODE = "GET_TEDARIKCI_BY_CODE";


// PARCALAR
export const GET_PARCA_LIST = "GET_PARCA_LIST";
export const DELETE_PARCA_BY_CODE = "DELETE_PARCA_BY_CODE";
export const GET_PARCA_BY_CODE = "GET_PARCA_BY_CODE";


// GOREVETIKETLER
export const GET_GOREVETIKET_LIST = "GET_GOREVETIKET_LIST";
export const DELETE_GOREVETIKET_BY_CODE = "DELETE_GOREVETIKET_BY_CODE";
export const GET_GOREVETIKET_BY_CODE = "GET_GOREVETIKET_BY_CODE";


// ANLASMALAR
export const GET_ANLASMA_LIST = "GET_ANLASMA_LIST";
export const DELETE_ANLASMA_BY_CODE = "DELETE_ANLASMA_BY_CODE";
export const GET_ANLASMA_BY_CODE = "GET_ANLASMA_BY_CODE";


// SUBELER
export const GET_SUBE_LIST = "GET_SUBE_LIST";
export const DELETE_SUBE_BY_CODE = "DELETE_SUBE_BY_CODE";
export const GET_SUBE_BY_CODE = "GET_SUBE_BY_CODE";


// MUSTERILER
export const GET_MUSTERI_LIST = "GET_MUSTERI_LIST";
export const DELETE_MUSTERI_BY_CODE = "DELETE_MUSTERI_BY_CODE";
export const GET_MUSTERI_BY_CODE = "GET_MUSTERI_BY_CODE";


// FATURABILGILERI
export const GET_FATURABILGILERI_LIST = "GET_FATURABILGILERI_LIST";
export const DELETE_FATURABILGILERI_BY_CODE = "DELETE_FATURABILGILERI_BY_CODE";
export const GET_FATURABILGILERI_BY_CODE = "GET_FATURABILGILERI_BY_CODE";


// ADRESLER
export const GET_ADRES_LIST = "GET_ADRES_LIST";
export const DELETE_ADRES_BY_CODE = "DELETE_ADRES_BY_CODE";
export const GET_ADRES_BY_CODE = "GET_ADRES_BY_CODE";


// MUSTERIFATURALAR
export const GET_MUSTERIFATURA_LIST = "GET_MUSTERIFATURA_LIST";
export const DELETE_MUSTERIFATURA_BY_CODE = "DELETE_MUSTERIFATURA_BY_CODE";
export const GET_MUSTERIFATURA_BY_CODE = "GET_MUSTERIFATURA_BY_CODE";

// MUSTERICIHAZLAR
export const GET_MUSTERICIHAZ_LIST = "GET_MUSTERICIHAZ_LIST";
export const DELETE_MUSTERICIHAZ_BY_CODE = "DELETE_MUSTERICIHAZ_BY_CODE";
export const GET_MUSTERICIHAZ_BY_CODE = "GET_MUSTERICIHAZ_BY_CODE";

// MUSTERIILETISIMBILGILER
export const GET_MUSTERIILETISIMBILGI_LIST = "GET_MUSTERIILETISIMBILGI_LIST";
export const DELETE_MUSTERIILETISIMBILGI_BY_CODE = "DELETE_MUSTERIILETISIMBILGI_BY_CODE";
export const GET_MUSTERIILETISIMBILGI_BY_CODE = "GET_MUSTERIILETISIMBILGI_BY_CODE";

// BANKAHESAPLAR
export const GET_BANKAHESAP_LIST = "GET_BANKAHESAP_LIST";
export const DELETE_BANKAHESAP_BY_CODE = "DELETE_BANKAHESAP_BY_CODE";
export const GET_BANKAHESAP_BY_CODE = "GET_BANKAHESAP_BY_CODE";

// HIZMETLER
export const GET_HIZMETLER_LIST = "GET_HIZMETLER_LIST";
export const DELETE_HIZMETLER_BY_CODE = "DELETE_HIZMETLER_BY_CODE";
export const GET_HIZMETLER_BY_CODE = "GET_HIZMETLER_BY_CODE";
