import {
  DELETE_TELEFONGORUSMESI_BY_CODE,
  GET_DEFINITION_LIST,
  GET_TELEFONGORUSMESI_BY_CODE,
  GET_TELEFONGORUSMESI_LIST
} from "../../constants";

const INITIAL_STATE = {
  telefongorusmesiList: [],
  telefongorusmesi: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TELEFONGORUSMESI_LIST:
      return {
        ...state,
        telefongorusmesiList: action.payload
      }

    case GET_TELEFONGORUSMESI_BY_CODE:
      return {
        ...state,
        telefongorusmesi: action.payload
      }

    case DELETE_TELEFONGORUSMESI_BY_CODE:
      return {
        ...state,
        //telefongorusmesiList: state.telefongorusmesiList.list.filter(telefongorusmesi => telefongorusmesi.code !== action.payload)
        telefongorusmesiList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

