import {toast} from "react-toastify";

export function toastMessageBuilder(response) {
  if (response === undefined) {
    toast.error('Beklenmedik bir hata oluştu')
  } else if (response.data.success) {
    toast.success(response.data.message);
  } else if (response.data.success === false) {
    toast.error(response.data.message);
  } else {
    toast.error('Beklenmedik bir hata oluştu')
  }
}

export function toastMessageBuilder2(response) {
  if (response === undefined) {
    toast.error('Beklenmedik bir hata oluştu')
  } else if (response.data.success) {
    toast.success(response.data.message);
  } else if (response.data.success === false) {
    if (response.data.validationErrors === null) {
      toast.error(response.data.message)
    } else {
      const validationErrors = Object.assign(response.data.validationErrors);
      const msg = customSortObj(validationErrors)
      toast.error(msg);
    }
  } else {
    toast.error('Beklenmedik bir hata oluştu')
  }
}

export function toastMessageBuilder3(type, msg) {
  if (type === undefined) {
    toast.error('Beklenmedik bir hata oluştu')
  } else if (type === "success") {
    toast.success(msg);
  } else if (type === "danger") {
    toast.error(msg);
  } else {
    toast.error('Beklenmedik bir hata oluştu')
  }
}

export function toastMessageBuilderValidationErrors(response) {
  if (response === undefined) {
    toast.error('Beklenmedik bir hata oluştu')
  } else if (response.success) {
    toast.success(response.message);
  } else if (response.success === false) {
    toast.error(response.validationErrors[0]);
  } else {
    toast.error('Beklenmedik bir hata oluştu')
  }
}


function sortObj(obj, order) {
  "use strict";

  var key,
    tempArry = [],
    i,
    tempObj = {};

  for (key in obj) {
    tempArry.push(key);
  }


  tempArry.sort(
    function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    }
  );

  console.log(tempArry)

  if (order === 'desc') {
    for (i = tempArry.length - 1; i >= 0; i--) {
      tempObj[tempArry[i]] = obj[tempArry[i]];
    }
  } else {
    for (i = 0; i < tempArry.length; i++) {
      tempObj[tempArry[i]] = obj[tempArry[i]];
    }
  }
  return tempObj;
}

//console.log("validationErrors", validationErrors)
//console.log("validationErrors-> keys", Object.keys(validationErrors))
//console.log("validationErrors-> values", Object.values(validationErrors))
//console.log("validationErrors-> entries", Object.entries(validationErrors))
function customSortObj(obj) {
  "use strict";
  var key,
    tempArry = [],
    tempObj = {};

  for (key in obj) {
    tempArry.push(key);
  }
  tempArry.sort(
    function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    }
  );
  tempObj[tempArry[0]] = obj[tempArry[0]];
  //return tempObj;
  return obj[tempArry[0]];
}
