import {
  DELETE_TEDARIKCI_BY_CODE,
  GET_DEFINITION_LIST,
  GET_TEDARIKCI_BY_CODE,
  GET_TEDARIKCI_LIST
} from "../../constants";

const INITIAL_STATE = {
  tedarikcilerList: [],
  tedarikciler: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TEDARIKCI_LIST:
      return {
        ...state,
        tedarikcilerList: action.payload
      }

    case GET_TEDARIKCI_BY_CODE:
      return {
        ...state,
        tedarikciler: action.payload
      }

    case DELETE_TEDARIKCI_BY_CODE:
      return {
        ...state,
        //tedarikcilerList: state.tedarikcilerList.list.filter(tedarikciler => tedarikciler.code !== action.payload)
        tedarikcilerList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

