import {
  GET_GENERATE_RANDOM_CODE,
  GET_SELECT_BOX_CABINET_LIST_BY_WHID,
  GET_SELECT_BOX_CITY,
  GET_SELECT_BOX_COUNTY,
  GET_SELECT_BOX_DEVICE,
  GET_SELECT_BOX_EMPLOYEE,
  GET_SELECT_BOX_INSTITUTION_LIST,
  GET_SELECT_BOX_MARKA_LIST,
  GET_SELECT_BOX_MODEL_LIST_BY_BRAND_ID,
  GET_SELECT_BOX_ROLE_LIST,
  GET_SELECT_BOX_WAREHOUSE_LIST
} from "../../constants";

const INITIAL_STATE = {
  selectBoxBrandList: {},
  selectBoxModelList: {},
  selectBoxWareHouseList: {},
  selectBoxRoleList: {},
  selectBoxCabinetList: {},
  selectBoxInstitutionList: {},
  selectBoxEmployeeList: {},
  selectBoxCityList: {},
  selectBoxCountyList: {},
  selectBoxDeviceList: {},
  randomCode:""
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {

    case GET_SELECT_BOX_INSTITUTION_LIST:
      return {
        ...state,
        selectBoxInstitutionList: action.payload
      }
    case GET_SELECT_BOX_MARKA_LIST:
      return {
        ...state,
        selectBoxBrandList: action.payload
      }

    case GET_SELECT_BOX_MODEL_LIST_BY_BRAND_ID:
      return {
        ...state,
        selectBoxModelList: action.payload
      }

    case GET_SELECT_BOX_WAREHOUSE_LIST:
      return {
        ...state,
        selectBoxWareHouseList: action.payload
      }
    case GET_SELECT_BOX_ROLE_LIST:
      return {
        ...state,
        selectBoxRoleList: action.payload
      }
    case GET_SELECT_BOX_CABINET_LIST_BY_WHID:
      return {
        ...state,
        selectBoxCabinetList: action.payload
      }

    case GET_SELECT_BOX_EMPLOYEE:
      return {
        ...state,
        selectBoxEmployeeList: action.payload
      }

    case GET_SELECT_BOX_CITY:
      return {
        ...state,
        selectBoxCityList: action.payload
      }

    case GET_SELECT_BOX_COUNTY:
      return {
        ...state,
        selectBoxCountyList: action.payload
      }

    case GET_SELECT_BOX_DEVICE:
      return {
        ...state,
        selectBoxDeviceList: action.payload
      }

    case GET_GENERATE_RANDOM_CODE:
      return {
        ...state,
        randomCode: action.payload
      }


    default:
      return state;
  }
}

