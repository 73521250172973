import {
  DELETE_KULLANICIIZIN_BY_CODE,
  GET_DEFINITION_LIST,
  GET_KULLANICIIZIN_BY_CODE,
  GET_KULLANICIIZIN_LIST
} from "../../constants";

const INITIAL_STATE = {
  kullaniciizinList: [],
  kullaniciizin: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_KULLANICIIZIN_LIST:
      return {
        ...state,
        kullaniciizinList: action.payload
      }

    case GET_KULLANICIIZIN_BY_CODE:
      return {
        ...state,
        kullaniciizin: action.payload
      }

    case DELETE_KULLANICIIZIN_BY_CODE:
      return {
        ...state,
        //kullaniciizinList: state.kullaniciizinList.list.filter(kullaniciizin => kullaniciizin.code !== action.payload)
        kullaniciizinList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

