import {DELETE_DOLAP_BY_CODE, GET_DEFINITION_LIST, GET_DOLAP_BY_CODE, GET_DOLAP_LIST} from "../../constants";

const INITIAL_STATE = {
  dolapList: [],
  dolap: {},
  definitionList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_DOLAP_LIST:
      return {
        ...state,
        dolapList: action.payload
      }

    case GET_DOLAP_BY_CODE:
      return {
        ...state,
        dolap: action.payload
      }

    case DELETE_DOLAP_BY_CODE:
      return {
        ...state,
        //dolapList: state.dolapList.list.filter(dolap => dolap.code !== action.payload)
        dolapList: state
      }

    case GET_DEFINITION_LIST:
      return {
        ...state,
        definitionList: action.payload
      }

    default:
      return state;
  }
}

